<template>
  <div class="base-menu-root">
    <component
      :is="$vuetify.breakpoint.smAndUp ? 'v-menu' : 'v-bottom-sheet'"
      v-model="open"
      offset-y
      :attach="'attach' in $attrs ? $attrs.attach : $vuetify.breakpoint.smAndUp ? `[data-menu='${id}']` : false"
      v-bind="$attrs"
    >
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div class="content-click-handler" @click="open = false">
            <slot />
        </div>
    </component>

    <div :data-menu="id" />
  </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

let instanceCount = 0;

export default Vue.extend({
  data() {
    return {
      id: instanceCount++,
      open: false,
    };
  },
});
</script>

<style scoped>
.base-menu-root,
.content-click-handler,
[data-menu] {
    display: contents;
}
</style>
